<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="认证信息"
        >
          <!-- <template slot="extra">
                      <a-button
                        class="editable-add-btn"
                        @click="handleAdd"
                        size="small"
                      >
                        新增
                      </a-button>
                    </template> -->
        </a-page-header>
      </div>
      <div ref="form">
        <!-- <a-form
                    :form="form"
                    layout="inline"
                    @submit="handleSubmit"
                    style="padding: 15px 0"
                    ref="form"
                  >
                    <a-form-item label="活动名称">
                      <a-input
                        v-decorator="[
                          'title',
                          { rules: [{ required: false }] },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item>
                      <a-button
                        type="primary"
                        html-type="submit"
                        @click="getExport(0)"
                      >
                        搜索
                      </a-button>
                    </a-form-item>
                  </a-form> -->
      </div>
      <a-table
        :columns="columns"
        :data-source="credentialsList"
        :pagination="false"
        :expandIcon="expandIcon"
        :row-key="(record) => record.id"
        size="middle"
        ref="tableList"
        class="components-table-demo-nested"
      >
        <span slot="dy_status" slot-scope="dy_status">
          {{ dy_status == 0 ? "未提交" : "" }}
          {{ dy_status == 1 ? "审核中" : "" }}
          {{ dy_status == 2 ? "审核成功" : "" }}
          {{ dy_status == 3 ? "审核失败" : "" }}
        </span>
        <span slot="ks_status" slot-scope="ks_status">
          {{ ks_status == 0 ? "未提交" : "" }}
          {{ ks_status == 1 ? "审核中" : "" }}
          {{ ks_status == 2 ? "审核成功" : "" }}
          {{ ks_status == 3 ? "审核失败" : "" }}
        </span>
        <span slot="b_status" slot-scope="b_status">
          {{ b_status == 0 ? "未提交" : "" }}
          {{ b_status == 1 ? "审核中" : "" }}
          {{ b_status == 2 ? "审核成功" : "" }}
          {{ b_status == 3 ? "审核失败" : "" }}
        </span>
        <span slot="xhs_status" slot-scope="xhs_status">
          {{ xhs_status == 0 ? "未提交" : "" }}
          {{ xhs_status == 1 ? "审核中" : "" }}
          {{ xhs_status == 2 ? "审核成功" : "" }}
          {{ xhs_status == 3 ? "审核失败" : "" }}
        </span>
        <span slot="wx_status" slot-scope="wx_status">
          {{ wx_status == 0 ? "未提交" : "" }}
          {{ wx_status == 1 ? "审核中" : "" }}
          {{ wx_status == 2 ? "审核成功" : "" }}
          {{ wx_status == 3 ? "审核失败" : "" }}
        </span>

        <a-table
          slot="expandedRowRender"
          slot-scope="num"
          v-if="num.data.length > 0"
          :columns="innerColumns"
          :data-source="num.data"
          :pagination="false"
          :row-key="(record) => record.platname"
        >
          <span slot="avatar" slot-scope="avatar">
            <img :src="avatar" alt="" />
          </span>
          <span slot="gender" slot-scope="gender">
            {{ gender == 0 ? "未知" : "" }}
            {{ gender == 1 ? "男性" : "" }}
            {{ gender == 2 ? "女性" : "" }}
          </span>
          <span slot="anchor_type" slot-scope="anchor_type">
            <span v-for="(item, index) in anchor_type" :key="item">
              <span v-if="index != 0">，</span>
              <span>{{ item }}</span>
            </span>
          </span>
          <span slot="screenshot" slot-scope="screenshot">
            <img :src="screenshot" alt="" class="screenshot" v-image-preview />
          </span>
        </a-table>
        <!-- <template slot="footer"> Footer </template> -->
      </a-table>
      <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      columns: [
        {
          title: "用户昵称",
          key: "users_nickname",
          dataIndex: "users_nickname",
          align: "center",
        },
        {
          title: "用户手机号",
          key: "users_mobile",
          dataIndex: "users_mobile",
          align: "center",
        },
        {
          title: "抖音状态",
          key: "dy_status",
          dataIndex: "dy_status",
          scopedSlots: { customRender: "dy_status" },
          align: "center",
        },
        {
          title: "快手状态",
          key: "ks_status",
          dataIndex: "ks_status",
          scopedSlots: { customRender: "ks_status" },
          align: "center",
        },
        {
          title: "B站状态",
          key: "b_status",
          dataIndex: "b_status",
          scopedSlots: { customRender: "b_status" },
          align: "center",
        },
        {
          title: "小红书状态",
          key: "xhs_status",
          dataIndex: "xhs_status",
          scopedSlots: { customRender: "xhs_status" },
          align: "center",
        },
        {
          title: "微信视频号状态",
          key: "wx_status",
          dataIndex: "wx_status",
          scopedSlots: { customRender: "wx_status" },
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "更新时间",
          key: "updated_at",
          dataIndex: "updated_at",
          align: "center",
        },
      ],
      innerColumns: [
        {
          title: "平台",
          key: "platname",
          dataIndex: "platname",
          align: "center",
        },
        {
          title: "昵称",
          key: "users_nickname",
          dataIndex: "users_nickname",
          align: "center",
        },
        {
          title: "头像",
          key: "avatar",
          dataIndex: "avatar",
          scopedSlots: { customRender: "avatar" },
          align: "center",
        },
        {
          title: "粉丝数",
          key: "fans_count",
          dataIndex: "fans_count",
          align: "center",
        },
        {
          title: "性别",
          key: "gender",
          dataIndex: "gender",
          scopedSlots: { customRender: "gender" },
          align: "center",
        },
        {
          title: "主播类型",
          key: "anchor_type",
          dataIndex: "anchor_type",
          scopedSlots: { customRender: "anchor_type" },
          align: "center",
        },
        {
          title: "第三方主页截图",
          key: "screenshot",
          dataIndex: "screenshot",
          scopedSlots: { customRender: "screenshot" },
          align: "center",
        },
      ],
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      credentialsList: [],
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },

    expandIcon(props) {
      if (props.record.data.length > 0) {
        if (props.expanded) {
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon type="caret-down" />{" "}
            </a>
          );
        } else {
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon type="caret-right" />
            </a>
          );
        }
      } else {
        return <span style="margin-right:0px"></span>;
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      console.log(data);
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/PlatformAuth/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var credentialsList = res.data.data;
            for (var i = 0; i < credentialsList.length; i++) {
              credentialsList[i].dy_info.platname = "抖音";
              credentialsList[i].ks_info.platname = "快手";
              credentialsList[i].b_info.platname = "B站";
              credentialsList[i].xhs_info.platname = "小红书";
              credentialsList[i].wx_info.platname = "微信视频号";
              credentialsList[i].dy_info.status = credentialsList[i].dy_status;
              credentialsList[i].ks_info.status = credentialsList[i].ks_status;
              credentialsList[i].b_info.status = credentialsList[i].b_status;
              credentialsList[i].xhs_info.status =
                credentialsList[i].xhs_status;
              credentialsList[i].wx_info.status = credentialsList[i].wx_status;
              var arr = [
                credentialsList[i].dy_info,
                credentialsList[i].ks_info,
                credentialsList[i].b_info,
                credentialsList[i].xhs_info,
                credentialsList[i].wx_info,
              ];
              var arrNew = [];
              for (var j = 0; j < arr.length; j++) {
                if (arr[j].status != 0) {
                  arrNew.push(arr[j]);
                }
              }
              credentialsList[i].data = arrNew;
            }
            that.changeTableH();
            that.credentialsList = credentialsList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },

    // 操作（删除、编辑）
    handel(btn, num) {
      var that = this;
      var data;
      that.activity_id = num.id;
      if (btn == "编辑") {
        data = {
          activity_id: num.id,
        };
        that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
        that.axios.get("/admin/activity/one", { params: data }).then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            that.uploadBtn = false;
            that.fileList = [
              {
                uid: "-1",
                name: "",
                status: "done",
                url: res.data.data.image_url,
                thumbUrl: res.data.data.image_url,
              },
            ];
            that.visible = true;
            that.modelTitle = "编辑活动";
            that.$nextTick(() => {
              that.addform.setFieldsValue({
                title: res.data.data.title,
                brokerage_description: res.data.data.brokerage_description,
                image_url: res.data.data.image_url,
                status: res.data.data.status,
              });
            });
          }
        });
      }
      if (btn == "删除") {
        that.$confirm({
          title: "提示",
          content: "是否" + btn + "此活动",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
            data = {
              activity_id: num.id,
            };
            that.axios
              .get("/admin/activity/destroy", { params: data })
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(btn + "成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      }
    },

    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}
.ant-table-wrapper {
  height: auto;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}
.ant-modal-body img {
  width: 150px;
}
.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
}
.section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
}
.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}
</style>